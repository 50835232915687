<template>
  <div class="server_wrap">
    <div class="server_list">
      <ul class="product_server_tab">
        <li v-for="(item,index) in tabList" :key="index" :class="{'active':item.isActive}" @click="tabEvent(item)">
          {{item.text}}
        </li>
        <el-tooltip class="item" effect="light"placement="right" v-if="tableceinfoData.length>0 && tabList[1].isActive">
          <div slot="content">发票将在1到3个工作日内开票完成，如果需要重<br/>新开发票，请电话联系：010-5845 7888</div>
          <i style="margin: 10px 0 0 5px;font-size: 18px" class="el-icon-info"/>
        </el-tooltip>
      </ul>

      <!--      订单记录-->
      <div class="my_order_main" v-if="tableData.length>0 && tabList[0].isActive">
        <!--      申请发票-->
        <div class="addInvoice" @click="applyInvoice">
          <div :class="{addInvoiceActives: multipleSelection.length < 2}" class="addInvoice_main">
            <i :class="{addInvoiceActive: multipleSelection.length < 2}" style="color: #345adf;font-size: 16px!important;" class="el-icon-takeaway-box"></i><span :class="{addInvoiceActive: multipleSelection.length < 2}" style="color: #345adf;font-size: 14px">申请开票</span>
          </div>
        </div>
        <el-table key="tableData" :data="tableData" style="width: 100%"  ref="multipleTable"  @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column>
          <el-table-column  label="订单类型" align="center">
            <template slot-scope="scope">
              <span >购买服务</span>
            </template>
          </el-table-column>
          <el-table-column prop="paymentTime" label="支付时间" align="center">
          </el-table-column>
          <el-table-column prop="payAmount" label="支付金额" align="center">
            <template slot-scope="scope">
              <span style="color: #ff7444">{{ scope.row.payAmount}}</span>元
            </template>
          </el-table-column>
          <el-table-column prop="invoiceStatus" label="开票状态" align="center">
            <template slot-scope="scope">
              <span >{{ scope.row.invoiceStatus == 0 ? "未开票" : scope.row.invoiceStatus == 20 ? "开票失败" : "已申请"}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <span @click="applyInvoices(scope.row)" style="cursor: pointer;color: #3d61e0" v-if="scope.row.invoiceStatus == 0">申请开票</span>
              <span @click="applyInvoices(scope.row)" style="cursor: pointer;color: #18c7ac" v-else-if="scope.row.invoiceStatus == 20">重新申请</span>
              <!--              <span @click="applyInvoice" style="cursor: pointer" v-else>修改申请</span>-->
            </template>
          </el-table-column>
        </el-table>

      </div>

      <!--      开票历史-->
      <div class="my_order_main" v-if="tableceinfoData.length>0 && tabList[1].isActive">

        <el-table key="tableceinfoData" :data="tableceinfoData" style="width: 100%">
          <el-table-column prop="invoiceTitle" label="发票抬头" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="提交时间" align="center">
          </el-table-column>
          <el-table-column prop="invoiceAmount" label="开票金额" align="center">
            <template slot-scope="scope">
              <span style="color: #ff7e52">{{ scope.row.invoiceAmount }}</span>元
            </template>
          </el-table-column>
          <el-table-column prop="invoiceStatus" label="开票状态" align="center">
            <template slot-scope="scope">
              <span >{{ scope.row.invoiceStatus == 10 ? "已申请" : scope.row.invoiceStatus == 30 ? "开票中" : scope.row.invoiceStatus == 40 ? "已邮寄" : scope.row.invoiceStatus == 20 ? "开票失败" : scope.row.invoiceStatus == 50 ? '开票完成' : ' '}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="invoiceType" label="发票类型" align="center"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!--              <span @click="editHistory(scope.row)" style="cursor: pointer;color: #eebd78" v-if="scope.row.invoiceStatus == 10">修改申请</span>-->
              <span @click="onlinePreviewInvoice(scope.row)" style="cursor: pointer;color: #eebd78; margin-right: 10px" v-if="scope.row.invoiceStatus == 50 && scope.row.invoiceType.indexOf('电子发票') != -1">查看</span>
              <span @click="downloadInvoice(scope.row)" style="cursor: pointer;color: #eebd78" v-if="scope.row.invoiceStatus == 50 && scope.row.invoiceType.indexOf('电子发票') != -1">下载</span>
            </template>
          </el-table-column>
          <el-table-column prop="payAmount" label="备注" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceStatus == 40">{{scope.row.expressCompany}};{{scope.row.expressNum}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--      发票抬头-->
      <div class="my_order_main"  v-if="tableDatas.length>0 && tabList[2].isActive">
        <div class="addInvoice" @click="addInvoice">
          <div class="addInvoice_main">
            <i style="color: #345adf;font-size: 16px!important;" class="el-icon-plus"></i><span style="color: #345adf;font-size: 14px">开票信息</span>
          </div>
        </div>
        <!--        开票信息-->
        <div>
          <template>
            <el-table
              key="tableDatas"
              :data="tableDatas"
              style="width: 100%">
              <el-table-column
                align="left"
                label="开票信息">
                <template slot-scope="scope">
                  <ul class="billingInformation">
                    <li>抬头：{{scope.row.invoiceTitle}}</li>
                    <li>纳税人识别号：{{scope.row.taxNum}}</li>
                    <li>注册地址：{{scope.row.regitAddr}}</li>
                    <li>注册电话：{{scope.row.phone}}</li>
                    <li>开户银行：{{scope.row.bankName}}</li>
                    <li>银行帐户：{{scope.row.bankAccount}}</li>
                  </ul>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="操作">
                <template slot-scope="scope">
                  <ul>
                    <li><span style="cursor: pointer;font-size: 14px" @click="edit(scope.row)">修改信息</span></li>
                    <li style="margin-top: 20px">
                      <el-switch
                        @change="switchs(scope.row)"
                        style="display: block"
                        v-model="scope.row.defaultTitle"
                        active-color="#dcdfe6"
                        inactive-color="#ff6a37"
                        active-value="0"
                        inactive-value="1"
                        active-text=""
                        inactive-text="默认">
                      </el-switch>
                    </li>
                  </ul>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>

      <div class="el_pagination_wrap">
        <el-pagination key="page1" v-if="tableData.length > 0 && tabList[0].isActive" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                       :current-page="purchaseTrial[0].current" :page-sizes="[10, 20, 30, 40 , 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <el-pagination  key="page2" v-if="tableceinfoData.length > 0 && tabList[1].isActive" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                        :current-page=" purchaseTrial[1].current " :page-sizes="[10, 20, 30, 40 , 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <el-pagination  key="page3" v-if="tableDatas.length > 0 && tabList[2].isActive" @current-change="handleCurrentChange" @size-change="handleSizeChange"
                        :current-page="purchaseTrial[2].current" :page-sizes=" [5, 10, 20, 30, 40 , 50, 100] " :page-size="sizes " layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="no_table_data" v-show="tableData.length == 0 && tabList[0].isActive || tableceinfoData.length == 0 && tabList[1].isActive || tableDatas.length == 0 && tabList[2].isActive ">
      <i>
        <img src="@/assets/images/common/orderN.png" alt="">
      </i>
      <p>暂无数据</p>
    </div>

    <!--    申请开票弹出框-->
    <el-dialog width="30%" center title="申请开票" :visible.sync="dialogFormVisibles">
      <el-form :model="forms">
        <el-form-item label="开票金额" :label-width="formLabelWidth">
          <span> <span style="color: #ff7e52">{{forms.invoiceAmount}}</span>元</span>
        </el-form-item>
        <el-form-item v-if="tableDatas.length > 0" label="抬头" :label-width="formLabelWidth">
          <!--          <el-select filterable placeholder="请输入账号" :remote-method="remoteMethod"-->
          <!--                     v-model="forms.invoiceTitleId" placeholder="请输入抬头">-->
          <!--            <el-option-->
          <!--              v-for="item in tableDatas"-->
          <!--              :key="item.id"-->
          <!--              :label="item.invoiceTitle"-->
          <!--              :value="item.id">-->
          <!--            </el-option>-->
          <!--          </el-select>-->

          <el-select v-model="forms.invoiceTitle" @change="changes" style="width: 100%" :remote-method="remoteMethod" remote filterable placeholder="请输入账号">
            <el-option
              v-for="item in listAll"
              :key="item.id"
              :label="item.invoiceTitle"
              :value="item.invoiceTitle">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="抬头" :label-width="formLabelWidth">
          <el-input v-model="forms.invoiceTitle" placeholder="请输入抬头" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" :label-width="formLabelWidth">
          <el-input v-model="forms.taxNum" placeholder="请输入纳税人识别号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注册地址" :label-width="formLabelWidth">
          <el-input v-model="forms.regitAddr"  placeholder="请输入注册地址" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注册电话" :label-width="formLabelWidth">
          <el-input v-model="forms.phone" placeholder="请输入注册电话" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" :label-width="formLabelWidth">
          <el-input v-model="forms.bankName" placeholder="请输入开户银行" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="银行帐户" :label-width="formLabelWidth">
          <el-input v-model="forms.bankAccount" placeholder="请输入银行帐户" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin: 0 10%">
          <el-col :span="12">
            <p>电子发票</p>
            <el-radio v-model="forms.invoiceType" label="电子发票-增值税普通发票" class="applyForInvoicingDialogRadio">企业增值税普通发票</el-radio>
          </el-col>
          <el-col :span="12">
            <p>纸质发票</p>
            <el-radio v-model="forms.invoiceType" label="纸质发票-增值税普通发票" class="applyForInvoicingDialogRadio">企业增值税普通发票</el-radio>
            <el-radio v-model="forms.invoiceType" label="纸质发票-增值税专用发票" class="applyForInvoicingDialogRadio">企业增值税专用发票</el-radio>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 115px;font-size: 15px"  type="primary" @click="confirmSubmitMessage" round>提交</el-button>
      </div>
    </el-dialog>

    <el-dialog width="30%" :visible.sync="dialogFormConfirmVisibles" title="确认发票信息" class="dialogFormConfirm">
      <p>请您确认开票信息及发票类型是否正确。</p>
      <el-row style="text-align: center">
        <el-button size="small" @click="notConfirmSubmitMessage">去确认</el-button>
        <el-button type="primary" size="small" @click="submits">确认无误</el-button>
      </el-row>
    </el-dialog>

    <!--    开票信息弹出框-->
    <el-dialog width="30%" center :title="!editFlag?'开票信息':'修改信息'" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="抬头" :label-width="formLabelWidth">
          <el-input v-model="form.invoiceTitle" placeholder="请输入抬头" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" :label-width="formLabelWidth">
          <el-input v-model="form.taxNum" placeholder="请输入纳税人识别号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注册地址" :label-width="formLabelWidth">
          <el-input v-model="form.regitAddr"  placeholder="请输入注册地址" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注册电话" :label-width="formLabelWidth">
          <el-input v-model="form.phone" placeholder="请输入注册电话" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" :label-width="formLabelWidth">
          <el-input v-model="form.bankName" placeholder="请输入开户银行" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="银行帐户" :label-width="formLabelWidth">
          <el-input v-model="form.bankAccount" placeholder="请输入银行帐户" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="width: 115px;font-size: 15px"  type="primary" @click="submit" round>提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>
<script>
  import { customStart, nvoiceList ,nvoicordereList ,nvoicorceinfoList ,addinvoicetitle,putinvoicetitle,invoiceId,appplyinvoicetitle,putinvoiceinfo,getinvoiceHistory,nvoiceListAll} from "@/request/trademarkPayment";
  let base64 = require('js-base64').Base64
  export default {
    name: "myServer",
    data () {
      return {
        //是否是开票历史修改
        isHistory:false,
        //是否编辑信息
        editFlag:false,
        multipleSelection: [],
        //申请开票
        dialogFormVisibles: false,
        //开票信息
        dialogFormVisible: false,
        dialogFormConfirmVisibles:false,
        //开票信息模块
        form: {
          id:undefined,
          invoiceTitle:'',
          taxNum:'',
          regitAddr:'',
          phone:'',
          bankName:'',
          bankAccount:'',
          invoiceType: null,
        },
        //申请开票模块
        forms: {
          invoiceTitle:'',
          invoiceAmount:'',
          invoiceTitleId:'',
          taxNum:'',
          regitAddr:'',
          phone:'',
          bankName:'',
          bankAccount:'',
          orderIds:[],
          invoiceType:null,
        },
        formLabelWidth: '120px',
        value1: true,
        //重新加载分页组件
        isShow: true,
        //默认购买
        flag: true,
        //记录分页
        purchaseTrial:[
          {
            //订单记录
            current: 1,
          },
          {
            //开票历史
            current: 1,
          },
          {
            //发票抬头
            current: 1,
          }
        ],
        tabList: [
          {
            text: "订单记录",
            isActive: true,
            typeId: 27,
            code:1
          },
          {
            text: "开票历史",
            isActive: false,
            typeId: 28,
            code:2
          },
          {
            text: "发票抬头",
            isActive: false,
            typeId: 29,
            code:3
          }],
        current: 1,
        size: 10,
        sizes:5,
        total: 0,
        tableData: [],
        tableceinfoData: [],
        tableDatas:[],
        changesHistoryId: '',
        invoiceTitle:'',
        //发票title 所有列表
        listAll:[]
      }
    },
    mounted () {

      if (!this.tool.getCookie("auth")) {
        this.$router.push({  path: "/index" })
        return;
      }
      //  获取订单记录接口
      this.nvoicordereList()
      //获取发票历史列表接口
      this.nvoicorceinfoList()
      //获取发票抬头列表接口
      this.getNvoiceList()
      //获取发票抬头列表接口
      this.nvoiceListAll()

    },
    methods: {
      //获取发票抬头所有接口
      nvoiceListAll(){
        nvoiceListAll().then(res => {
          this.listAll = res.data
          this.forms.invoiceTitleId = res.data.length == 0 ? '' : res.data[0].id
        })
      },
      //返回顶部
      toTop () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
      //当前页数变化触发
      handleCurrentChange (val) {
        if(this.tabList[0].isActive){
          this.purchaseTrial[0].current = val;
          //  获取订单记录接口
          this.nvoicordereList()
        }else if(this.tabList[1].isActive){
          this.purchaseTrial[1].current = val;
          //获取发票历史列表接口
          this.nvoicorceinfoList()
        }else {
          this.purchaseTrial[2].current = val;
          //获取发票抬头列表接口
          this.getNvoiceList()
        }

        this.toTop();
      },
      //当前页面大小变化触发
      handleSizeChange (val) {
        this.$nextTick(() => {
          if(this.tabList[0].isActive){
            this.size = val;
            //  获取订单记录接口
            this.nvoicordereList()
          }else if(this.tabList[1].isActive){
            this.size = val;
            //获取发票历史列表接口
            this.nvoicorceinfoList()
          }else {
            this.sizes = val;
            //获取发票抬头列表接口
            this.getNvoiceList()
          }
          this.toTop();
        })

      },
      //抬头失去光标操作
      selectBlur(e){
        Vue.set(this.forms,"invoiceTitle",e.target.value)
      },
      remoteMethod(e){
        let res = this.tableDatas.filter(item => item.invoiceTitle == e)

        if(res.length > 0){
          this.forms.invoiceTitleId = res[0].id
          this.forms.invoiceTitle = res.invoiceTitle
        }else {
          if(this.isHistory){
            // this.forms.invoiceTitleId = ''
            this.forms.invoiceTitle = e
          }else {
            this.forms.invoiceTitleId = ''
            this.forms.invoiceTitle = e
          }

        }

      },
      //改变默认状态
      switchs(data){
        putinvoicetitle({
          "id": data.id,
          "defaultTitle": data.defaultTitle == 0 ? '0' : '1'
        }).then(res => {
          if (res.code == 0) {
            // this.$message({
            //   message: '修改默认发票抬头',
            //   type: 'success'
            // });
            //获取发票抬头列表接口
            this.getNvoiceList()
          }
        })
      },
      //上方申请开票按钮触发，多发票使用
      applyInvoice(){
        if(this.multipleSelection.length < 2) return;
        if(this.tableDatas.length == 0){
          this.forms.invoiceTitle = '',
            this.forms.taxNum = '',
            this.forms.regitAddr = '',
            this.forms.phone = '',
            this.forms.bankName = '',
            this.forms.bankAccount = '',
            this.forms.invoiceType = null
        }

        this.isHistory = false
        if(this.multipleSelection.length == 0){
          this.$message({
            message: '请选择要申请开票的订单',
            type: 'warning'
          });
          return;
        }

        this.changes()
        this.dialogFormVisibles = true
      },
      //editHistory
      //开票历史修改
      editHistory(item){
        this.isHistory = true
        // this.forms.invoiceAmount += item.amount
        // this.forms.orderIds.push(item.id)
        this.changesHistory(item.id, item.invoiceType)
        this.dialogFormVisibles = true
      },
      //行申请开票信息，单发票使用
      applyInvoices(item){
        if(this.tableDatas.length == 0){
          this.forms.invoiceTitle = '',
            this.forms.taxNum = '',
            this.forms.regitAddr = '',
            this.forms.phone = '',
            this.forms.bankName = '',
            this.forms.bankAccount = '',
            this.forms.invoiceType = null
        }
        this.$refs.multipleTable.clearSelection();
        this.isHistory = false
        this.forms.invoiceAmount = item.amount
        this.forms.orderIds.push(item.id)
        this.changes()
        this.dialogFormVisibles = true
      },
      //修改开票历史
      changesHistory(id, invoiceType){
        this.forms.invoiceAmount = 0
        this.changesHistoryId = id
        getinvoiceHistory(id).then(res => {
          if(res.code == 0){
            this.forms.invoiceTitleId = res.data.invoiceTitleId
            this.forms.invoiceTitle = res.data.invoiceTitle
            this.forms.invoiceAmount += res.data.invoiceAmount
            this.forms.taxNum = res.data.taxNum
            this.forms.regitAddr = res.data.regitAddr
            this.forms.phone = res.data.phone
            this.forms.bankName = res.data.bankName
            this.forms.bankAccount = res.data.bankAccount
            this.forms.invoiceType = invoiceType
          }
        })
      },
      //选择申请开票的抬头
      changes(e){
        if(this.tableDatas.length == 0) return
        if(e){
          let res = this.tableDatas.filter(item => item.invoiceTitle == e)
          if(res.length > 0){
            this.forms.invoiceTitleId = res[0].id
          }else {
            this.forms.invoiceTitleId = ''
          }
        }
        invoiceId(this.forms.invoiceTitleId).then(res => {
          if(res.code == 0){
            this.forms.invoiceTitle = res.data.invoiceTitle
            this.forms.taxNum = res.data.taxNum
            this.forms.regitAddr = res.data.regitAddr
            this.forms.phone = res.data.phone
            this.forms.bankName = res.data.bankName
            this.forms.bankAccount = res.data.bankAccount
          }
        })
      },
      //添加开票信息
      addInvoice(){
        this.editFlag = false
        this.form.invoiceTitle = '',
          this.form.taxNum = '',
          this.form.regitAddr = '',
          this.form.phone = '',
          this.form.bankName = '',
          this.form.bankAccount = ''
        this.form.invoiceType = null
        this.form.id = undefined
        this.dialogFormVisible = true
      },
      //抬头信息编辑
      edit(data){
        this.editFlag = true
        this.form.id = data.id
        this.form.invoiceTitle = data.invoiceTitle
        this.form.taxNum = data.taxNum
        this.form.regitAddr = data.regitAddr
        this.form.phone = data.phone
        this.form.bankName = data.bankName
        this.form.bankAccount = data.bankAccount
        this.form.invoiceType = data.invoiceType
        this.dialogFormVisible = true
      },

      //添加发票抬头接口
      submit(){
        if(this.form.invoiceTitle && this.form.taxNum && this.form.regitAddr && this.form.phone && this.form.bankName && this.form.bankAccount){
          this.editFlag ? putinvoicetitle(this.form).then(res => {
            if(res.code == 0){
              this.$message({
                message: this.editFlag ? '开票信息修改成功' : '开票信息添加成功',
                type: 'success'
              });
              this.getNvoiceList()
            }else {
              this.$message.error(this.editFlag ? '开票信息修改失败' : '开票信息添加失败',);
            }
          }) : addinvoicetitle(this.form).then(res => {
            if(res.code == 0){
              this.$message({
                message: this.editFlag ? '开票信息修改成功' : '开票信息添加成功',
                type: 'success'
              });
              this.getNvoiceList()
            }else {
              this.$message.error(this.editFlag ? '开票信息修改失败' : '开票信息添加失败',);
            }
          })

          this.dialogFormVisible = false

        }else{
          this.$message.error('开票信息填写不全');
        }

      },
      //再次确认信息
      confirmSubmitMessage(){
        if (this.forms.invoiceTitle && this.forms.taxNum && this.forms.regitAddr && this.forms.phone && this.forms.bankName && this.forms.bankAccount && this.forms.invoiceType){
          this.dialogFormConfirmVisibles = true
        }
        else {
          this.$message("存在未填写信息")
        }
      },
      //再次确认信息点击取消按钮触发
      notConfirmSubmitMessage(){
        this.dialogFormConfirmVisibles = false
      },
      //再次确认信息点击确认触发 确认申请开票
      submits(){
        this.dialogFormConfirmVisibles = false

        if(this.tableDatas.length > 0){
          if(this.forms.invoiceTitle && this.forms.taxNum && this.forms.regitAddr && this.forms.phone && this.forms.bankName && this.forms.bankAccount){

            if(this.isHistory){
              putinvoiceinfo({
                "id": this.changesHistoryId,
                "invoiceTitleId": this.forms.invoiceTitleId,
                "invoiceTitle": this.forms.invoiceTitle,
                "taxNum": this.forms.taxNum,
                "regitAddr": this.forms.regitAddr,
                "phone": this.forms.phone,
                "bankName": this.forms.bankName,
                "bankAccount": this.forms.bankAccount,
                "invoiceType":this.forms.invoiceType
              }).then(res => {
                if(res.code == 0){
                  this.$message({
                    message: '修改申请成功',
                    type: 'success'
                  });
                  this.forms.orderIds = []
                  //获取发票历史列表接口
                  this.nvoicorceinfoList()
                }else {
                  this.$message.error('修改申请失败',);
                  this.forms.orderIds = []
                }
              })
            }
            else{
              // return
              appplyinvoicetitle(this.forms).then(res => {
                if(res.code == 0){
                  this.$message({
                    message: '申请开票成功',
                    type: 'success'
                  });
                  this.$refs.multipleTable.clearSelection();
                  this.forms.orderIds = []
                  //  获取订单记录接口
                  this.nvoicordereList()
                }else {
                  this.$message.error('申请开票失败',);
                  this.$refs.multipleTable.clearSelection();
                  this.forms.orderIds = []
                }
              })
            }

            this.dialogFormVisibles = false
            this.forms.invoiceAmount = 0
            this.multipleSelection = []
          }
          else {
            this.$message.error('开票信息填写不全');
          }
        }
        else {
          if(this.forms.invoiceTitle && this.forms.taxNum && this.forms.regitAddr && this.forms.phone && this.forms.bankName && this.forms.bankAccount){
            if(this.isHistory){
              putinvoiceinfo({
                "id": this.changesHistoryId,
                "invoiceTitleId": this.forms.invoiceTitleId,
                "invoiceTitle": this.forms.invoiceTitle,
                "taxNum": this.forms.taxNum,
                "regitAddr": this.forms.regitAddr,
                "phone": this.forms.phone,
                "bankName": this.forms.bankName,
                "bankAccount": this.forms.bankAccount,
                "invoiceType":this.forms.invoiceType
              }).then(res => {
                if(res.code == 0){
                  this.$message({
                    message: '修改申请成功',
                    type: 'success'
                  });
                  this.forms.orderIds = []
                  //获取发票历史列表接口
                  this.nvoicorceinfoList()
                }else {
                  this.$message.error('修改申请失败',);
                  this.forms.orderIds = []
                }
              })
            }else{
              // return
              appplyinvoicetitle(this.forms).then(res => {
                if(res.code == 0){
                  this.$message({
                    message: '申请开票成功',
                    type: 'success'
                  });
                  this.$refs.multipleTable.clearSelection();
                  this.forms.orderIds = []
                  //  获取订单记录接口
                  this.nvoicordereList()
                }else {
                  this.$message.error('申请开票失败',);
                  this.$refs.multipleTable.clearSelection();
                  this.forms.orderIds = []
                }
              })
            }


            this.dialogFormVisibles = false

            this.forms.invoiceAmount = 0

            this.multipleSelection = []
          }else {
            this.$message.error('开票信息填写不全');
          }
        }
      },
      //订单选择
      handleSelectionChange(val) {
        this.forms.invoiceAmount = 0
        this.forms.orderIds = []
        val.map(item => {
          this.forms.invoiceAmount += item.amount
          this.forms.orderIds.push(item.id)
          return item
        })
        this.multipleSelection = val;

      },
      //  获取订单记录接口
      nvoicordereList(){
        nvoicordereList({
          size: this.size,
          current: this.purchaseTrial[0].current
        }).then(res => {
          if (res.code == 0) {
            this.tableData = res.data.records;
            if(this.tabList[0].isActive) this.total = res.data.total;
          }
        })
      },
      //获取发票历史列表接口
      nvoicorceinfoList(){
        nvoicorceinfoList({
          size: this.size,
          current: this.purchaseTrial[1].current
        }).then(res => {
          if (res.code == 0) {
            this.tableceinfoData = res.data.records;
            if(this.tabList[1].isActive) this.total = res.data.total;
          }
        })
      },
      //获取发票抬头列表接口
      getNvoiceList(){
        nvoiceList({
          size: this.sizes,
          current: this.purchaseTrial[2].current
        }).then(res => {
          if (res.code == 0) {
            this.tableDatas = res.data.records;
            if(this.tabList[2].isActive) this.total = res.data.total;
          }
        })
      },
      //   产品服务切换
      tabEvent (data) {
        if (data.isActive) {
          return;
        }
        this.isShow = false
        this.tabList.forEach((item, index) => {
          item.isActive = false;
        })
        data.isActive = !data.isActive;
        if(data.typeId == 27){
          //  获取订单记录接口
          this.nvoicordereList()
          //获取发票抬头列表接口
          this.nvoiceListAll()
        }else if(data.typeId == 28){
          //获取发票历史列表接口
          this.nvoicorceinfoList()
          //获取发票抬头列表接口
          this.nvoiceListAll()
        }else if(data.typeId == 29){
          this.getNvoiceList()
        }
        this.$nextTick(() => {
          this.isShow = true
        })
      },

      onlinePreviewInvoice(row){
        //可以打开任意格式的文件
        // let onlinePreviewPrifix = "https://viewtest.lanternfish.cn/onlinePreview?url="
        // let downloadUrl = window.location.origin + row.invoiceUrl
        // let encodeFileUrl = encodeURIComponent(base64.encode(downloadUrl))
        // window.open(onlinePreviewPrifix + encodeFileUrl)

        //专门打开pdf的页面
        let routeData = this.$router.resolve({
          path: '/pdfOnlinePreview',
          query: {
            url: row.invoiceUrl.substring(row.invoiceUrl.lastIndexOf("/")+1)
          }
        })
        window.open(routeData.href, '_blank')
      },
      downloadInvoice(row){
        let alink = document.createElement("a")
        alink.href=window.location.origin + row.invoiceUrl
        alink.download = `${row.invoiceTitle}.pdf`
        alink.click()
      },
    }
  }
</script>
<style lang="less" scoped>
  .server_wrap {
    .server_list {
      .el_pagination_wrap {
        text-align: center;
        margin-top: 10px;
      }
      .product_server_tab {
        display: flex;
        margin-bottom: 30px;
        li {
          width: 100px;
          height: 36px;
          background-color: #f5f7f9;
          border-radius: 3px;
          font-size: 16px;
          color: @blue;
          text-align: center;
          line-height: 36px;
          margin-right: 1px;
          cursor: pointer;
          &.active {
            background: @blue;
            color: #fff;
          }
        }
      }

      .radio_group{
        /deep/.el-radio-button__inner{
          width: 117px;
          height: 45px;
          font-size: 18px;
          color: rgb( 46, 84, 217 );
        }

        /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
          color: #ffffff!important;
        }
      }

      /deep/.tableHeader{
        text-align: center;
        font-size: 16px;
        color: rgb( 51, 51, 51 );
      }

      /deep/.tableCell{
        text-align: center;
        font-size: 14px;
        color: rgb( 51, 51, 51 );
      }



      background-color: #ffffff;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      padding: 40px 30px;
      margin-bottom: 10px;
      .server_list_item {
        display: flex;
        li {
          position: relative;
          padding: 0 20px;
          &.item1 {
            flex-basis: 340px;
            h5 {
              font-size: 18px;
              color: #454545;
              i {
                display: inline-block;
                width: 20px;
                margin-right: 10px;
                vertical-align: -3px;
                img {
                  width: 100%;
                }
              }
            }
            p {
              color: #888888;
              font-size: 14px;
              &.date {
                margin: 40px 0 20px;
              }
              span {
                font-size: 18px;
                color: @blue;
                &.over {
                  color: #b3b9c1;
                }
                &.open {
                  color: #ffd145;
                }
              }
            }
          }
          &.item2 {
            flex: 1;
            display: flex;
            align-items: center;
            dl {
              flex: 1;
              font-size: 14px;
              color: #717171;
              text-align: center;
              dt {
                margin-bottom: 40px;
                span {
                  font-size: 18px;
                  color: @blue;
                }
              }
            }
          }
          &.item3 {
            flex: none;
            flex-basis: 200px;
            dl {
              dt {
                span {
                  color: #ffd145;
                }
              }
              &:last-child {
                margin-left: 20px;
              }
            }
          }
          &::after {
            position: absolute;
            top: 50%;
            margin-top: -70px;
            right: 0;
            content: "";
            width: 1px;
            height: 140px;
            background: #dbdcdd;
          }
          &:last-child {
            &::after {
              background: transparent;
            }
          }
        }
      }
    }
    .my_order_main {
      .billingInformation{
        li{
          line-height: 26px;
        }
      }
      padding: 32px 10px;
      background: #fff;
      position: relative;
      /*box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);*/
      min-height: calc(100vh - 355px);
      .order_upload,
      .order_detail {
        display: inline-block;
        width: 20x;
        vertical-align: middle;
        cursor: pointer;
        img {
          width: 100%;
          display: block;
        }
      }
      .order_detail {
        width: 20px;
        margin-left: 16px;
        vertical-align: -4px;
      }
      .el_pagination_wrap {
        text-align: center;
        margin-top: 10px;
      }
      .no_table_data {
        margin-top: 60px;
        text-align: center;
        i {
          display: block;
          width: 80px;
          height: 80px;
          margin: 0 auto 40px;
          img {
            display: block;
            width: 100%;
          }
        }
        p {
          font-size: 15px;
          color: #949aa9;
          span {
            cursor: pointer;
            color: @blue;
          }
        }
      }
      .btn {
        width: 76px;
        height: 32px;
        background-color: #53dee3;
        border-radius: 5px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        padding: 0;
        border: none;
        &.pay {
          background-color: #fff;
          border: solid 1px #53dee3;
          color: #53dee3;
        }
        &:hover {
          box-shadow: none;
        }
      }

      .addInvoice{
        cursor: pointer;
        position: absolute;
        top: -30px;
        right: 46px;
        .addInvoiceActive{
          color: #c0c4cc!important;

        }
        .addInvoiceActives{
          cursor: not-allowed ;
          border: 1px solid #c0c4cc!important;
        }
        .addInvoice_main{
          height: 37px;
          width: 104px;
          border: 1px solid #3e63e0;
          border-radius: 20px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          i{
            /*font-weight: bold;*/
            margin-right: 4px;
          }
        }
      }

      .invoiceMessage{
        position: absolute;
        top: -18px;
        left: 10px;

        .warningMark{
          display: inline-block;
          padding: 0px 12px;
          margin-right: 5px;
          border-radius: 50%;
          font-size: 20px;
          font-weight: bolder;
          color: #ffffff;
          background-color: #000000;
        }
        .warningMessage{
          display: inline-block;
          padding: 5px 10px;
          background-color: rgb(239,90,161);
        }
      }
    }

    .el_pagination_wrap {
      text-align: center;
      margin-top: 10px;
    }
    .no_table_data {
      text-align: center;
      background: #fff;
      padding-top:100px;
      min-height: calc(100vh - 208px);
      box-sizing: border-box;
      i {
        display: block;
        width: 80px;
        height: 80px;
        margin: 0 auto 40px;
        img {
          display: block;
          width: 100%;
        }
      }
      p {
        font-size: 15px;
        color: #949aa9;
        span {
          cursor: pointer;
          color: @blue;
        }
      }
    }

    .dialogFormConfirm{
      p{
        display: inline-block;
        margin: 0 0 10px;
      }
    }
  }
</style>
<style lang="less">
  .applyForInvoicingDialogRadio .el-radio__label{
    width: 85%;
    text-overflow: ellipsis;
    white-space: normal;
    vertical-align: middle;
    display: inline-block;
  }
  .dialogFormConfirm .el-dialog__body{
    padding: 0 20px 30px;
  }
</style>
